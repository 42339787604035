import Swal from "sweetalert2";
require('es6-promise').polyfill(); //this creates a global polyfil for axios. see: https://github.com/stefanpenner/es6-promise
import axios from "axios";

import jQuery from 'jquery';
window.bwjQuery = jQuery.noConflict( true );

const bwAddButtonClick = (el, listener) => {
	el.addEventListener("click", listener, false);
};

var bwpcart = {
	offerId: window.bwvars ? window.bwvars.offerId : 0,
	partnerId: window.bwvars ? window.bwvars.partnerId : 0,
	checkoutUrl: window.bwvars ? window.bwvars.checkoutUrl : "https://app.bandwango.com",
	isCartBuilt: false,
	externalId: 0,
	cartOpen: false,
	getExternalId: function() {
		// Check to see if External ID exists
		if (localStorage.getItem("bwuid-"+bwpcart.offerId)) {
			// if it exists return it
			return localStorage.getItem("bwuid-"+bwpcart.offerId);
		} else {
			return bwpcart.externalId;
		}
	},
	isItMobile: function() {
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	},
	cartWidth: function() {
		if (bwpcart.isItMobile()) {
			return Math.max(
				document.documentElement.clientWidth,
				window.innerWidth || 0
			);
		} else {
			// Gets max width and divides by 2 since cart only takes up half the screen
			return (
				Math.max(document.documentElement.clientWidth, window.innerWidth || 0) /
				3
			);
		}
	},
	cartHeight: function() {
		// NOTE: this works, but for the clients who place this in a small container
		// it will be super small. A min-height of 500px given in overrides.scss.
		// Gets height.
		var height = Math.max(
			document.documentElement.clientHeight,
			window.innerHeight || 0
		);
		height -= 40;

		return height;
	},
	buildCart: function() {
		if(this.isCartBuilt) return; //skip if already run
		this.isCartBuilt = true;
		// This function builds the inital cart
		// sets all the initial parameters
		bwpcart.externalId = this.getExternalId();
		console.log("Building cart with id " + bwpcart.externalId);

		// build the slider
		this.buildSlider();

		// get current cart count
		this.getCartCount();

		// populate html for modules
		this.renderCustomCss();

		// populate html for modules
		//this.renderModules();

		// prepare buy now buttons
		// this.buildButtons();

		window.addEventListener("message", this.receiveMessage, false);
	},
	updateSliderSizes() {
		var cartHeight = this.cartHeight() - 20;
		var cartWidth = this.cartWidth();
		document.getElementById("bwcartbox").style.right = `-${cartWidth}px`;
		document.getElementById("bwiframe__wrapper").style.width = `${cartWidth}px`;

		document.getElementById("bwiframe").setAttribute("width", cartWidth);
		document.getElementById("bwiframe").setAttribute("height", cartHeight);
	},
	buildSlider: function() {
		var cartHeight = this.cartHeight() - 20;
		var cartWidth = this.cartWidth();

		// find container
		if (!document.getElementById("bwcontainer")) {
			var bwelem = document.createElement("div");
			bwelem.setAttribute("id", "bwcontainer");
			document.body.appendChild(bwelem);
		}

		var container = document.getElementById("bwcontainer");

		// inject shadowbox
		document.getElementById("bwcontainer").innerHTML +=
			'<div id="bwblackbox"></div>';
		// inject cart
		document.getElementById("bwcontainer").innerHTML +=
			`<div id="bwcartbox" style="right:-${cartWidth}px;">
				<div id="bwiframe__wrapper" class="bwiframe__wrapper" style="height:100vh; width:${cartWidth}px;">
					<iframe id="bwiframe" frameborder="0" width="${cartWidth}" height="${cartHeight}" style="padding-top:40px; position:fixed;"></iframe>
				</div>
				<div id="bwclosebutton" class="bw-close-icon"></div>
				<div id="open-cart" class="open-cart">
					<p id="open-cart-qty" class="cart-qty">0</p>
					<svg class="open-cart--icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="20px" height="20px" viewBox="0 0 16 16">
						<path d="M14 13.1v-1.1h-9.4l0.6-1.1 9.2-0.9 1.6-6h-12.3l-0.7-3h-3v1h2.2l2.1 8.4-1.3 2.6v1.5c0 0.8 0.7 1.5 1.5 1.5s1.5-0.7 1.5-1.5-0.7-1.5-1.5-1.5h7.5v1.5c0 0.8 0.7 1.5 1.5 1.5s1.5-0.7 1.5-1.5c0-0.7-0.4-1.2-1-1.4z"/>
					</svg>
				</div>
			</div>`;
		// TODO build cart icon ... inject cart icon
		document.getElementById("bwcontainer").innerHTML += "";

		// set event listners
		document
			.getElementById("open-cart")
			.addEventListener("click", this.openCart, false);
		document
			.getElementById("bwblackbox")
			.addEventListener("click", this.closeSlider, false);
		document
			.getElementById("bwclosebutton")
			.addEventListener("click", this.closeSlider);

		var openCartElements = document.getElementsByClassName("bw-open-cart");

		for (var i = 0; i < openCartElements.length; i++) {
			openCartElements[i].addEventListener("click", this.openCart, false);
		}

		if (!this.isItMobile()) {
			window.onresize = this.resizeSlider;
		}
	},
	buildButtons: function() {
		// Find all buttons with bandwango data
		var bwButtons = document.getElementsByClassName("bwbutton");
		var bwIframeButtons = document.getElementsByClassName("bwiframebutton");

		for (var i = 0; i < bwButtons.length; i++) {
			bwAddButtonClick(bwButtons[i], this.showAddToCart)
		}
		
		for (var i = 0; i < bwIframeButtons.length; i++) {
			bwAddButtonClick(bwIframeButtons[i], this.showIframeModal)
		}
	},
	showAddToCart: function(event) {
		bwpcart.updateSliderSizes();

		var itemId = event.target.dataset.bwitemid || event.currentTarget.dataset.bwitemid;
		var itemType = event.target.dataset.bwitemtype || event.currentTarget.dataset.bwitemtype;
		var iframe = document.getElementById("bwiframe");
		var iframeUrl =
			bwpcart.checkoutUrl +
			"/checkout/addToCart?partnerId=" +
			bwpcart.partnerId +
			"&offerId=" +
			bwpcart.offerId +
			"&itemId=" +
			itemId +
			"&itemType=" +
			itemType +
			"&template=embed&externalOrderId=" +
			bwpcart.externalId;

		iframe.setAttribute("src", iframeUrl);

		document.getElementById("bwblackbox").style.display = "block";
		document.getElementById("bwcartbox").style.right = "0";
		// prevent scrolling (hacky because of ios)
		document.body.style.top = `-${window.scrollY}px`;
		document.body.style.position = 'fixed';
		document.body.style.width = '100%';

		bwpcart.cartOpen = open;

		// / if it's mobile
		if (bwpcart.isItMobile()) {
			var vh = window.innerHeight * 0.01;
			document
				.getElementById("bwcartbox")
				.style.setProperty("--vh", "".concat(vh, "px"));
		}
	},
	showIframeModal: function(event) {
		var itemId = event.target.dataset.bwitemid || event.currentTarget.dataset.bwitemid;
		var itemType = event.target.dataset.bwitemtype || event.currentTarget.dataset.bwitemtype;
		var iframeUrl =
			bwpcart.checkoutUrl +
			"/checkout/" +
			bwpcart.partnerId +
			"/x/" +
			bwpcart.offerId +
			"/y/" +
			itemType + "/" + itemId;
			"/z/";

		bwpcart.modal(iframeUrl);
	},
	closeSlider: function() {
		console.log("Closing slider with id " + bwpcart.externalId);

		// restore scrolling (hacky because of ios)
		const scrollY = document.body.style.top;
		document.body.style.position = '';
		document.body.style.top = '';
		document.body.style.width = '';
		window.scrollTo(0, parseInt(scrollY || '0') * -1);

		document.getElementById("bwblackbox").style.display = "none";
		// Because JavaScript is stupid I have to set this with bwpcart since I can't call the function with this
		var rightShouldBe = bwpcart.cartWidth();
		document.getElementById("bwcartbox").style.right =
			"-" + rightShouldBe + "px";

		var iframe = document.getElementById("bwiframe");
		iframe.setAttribute("src", "");
		bwpcart.cartOpen = false;
	},
	getCartCount: function() {
		if (bwpcart.externalId != 0) {
			console.log("Grabbing cart count with id " + bwpcart.externalId);
			var url =
				bwpcart.checkoutUrl +
				"/api/orders/getExternalOrder/" +
				bwpcart.externalId;
			
			axios.get(url)
			.then(response => response.data)
			.then((data) => {
				if (data.totalItems > 0 && data.status == "draft") {
					//document.getElementById('open-cart-qty').innerHTML = data.totalItems;
					bwpcart.updateCartCount(data.totalItems);
				} else if (data.totalItems == 0 || data.status != "draft") {
					localStorage.setItem("bwuid-"+bwpcart.offerId, "");
					bwpcart.externalId = 0;
					//document.getElementById('open-cart-qty').innerHTML = 0;
					bwpcart.updateCartCount(0);
				}
				console.log("Cart count is " + data.totalItems);
			});
		} else {
			console.log("Can not get cart count, no external id");
			//document.getElementById('open-cart-qty').innerHTML = 0;
			bwpcart.updateCartCount(0);
		}
	},
	updateCartCount: function(qty) {
		document.getElementById("open-cart-qty").innerHTML = qty;

		var openCartQtyElements = document.getElementsByClassName(
			"bw-open-cart-qty"
		);

		for (var i = 0; i < openCartQtyElements.length; i++) {
			openCartQtyElements[i].innerHTML = qty;
		}

		// If the cart icon is hidden, always show it if there is something in the cart.
		if( qty > 0 ) {
			document.querySelector('#open-cart').style.display = 'block';
		}
	},
	openCart: function() {
		console.log("Opening cart with id " + bwpcart.externalId);

		bwpcart.updateSliderSizes();
		// set url of slider
		var iframe = document.getElementById("bwiframe");
		var iframeUrl =
		bwpcart.checkoutUrl +
		"/checkout/" +
		bwpcart.partnerId +
		"/x/" +
		bwpcart.offerId +
		"/y/cart?externalOrderId=" +
		bwpcart.externalId +
		"&template=embed";
		// assign iframe url
		iframe.setAttribute("src", iframeUrl);
		if (bwpcart.cartOpen == false) {
			// prevent scrolling (hacky because of ios)
			document.body.style.top = `-${window.scrollY}px`;
			document.body.style.position = 'fixed';
			document.body.style.width = '100%';

			document.getElementById("bwblackbox").style.display = "block";
			document.getElementById("bwcartbox").style.right = "0";
			// if it's mobile
			if (bwpcart.isItMobile()) {
				var vh = window.innerHeight * 0.01;
				document
					.getElementById("bwcartbox")
					.style.setProperty("--vh", "".concat(vh, "px"));
			}
			bwpcart.cartOpen = true;
		} else {
			bwpcart.closeSlider();
			bwpcart.cartOpen = false;
		}
	},
	receiveMessage: function(event) {
		if (event.data && typeof event.data !== "string") {
			if (event.data.bandwango) {
				console.log("Message received from cart", event.data.bandwango);
				if (
					event.data.bandwango.cartItems &&
					event.data.bandwango.cartItems > 0
				) {
					//document.getElementById('open-cart-qty').innerHTML = event.data.bandwango.cartItems;
					bwpcart.updateCartCount(event.data.bandwango.cartItems);
				} else {
					//document.getElementById('open-cart-qty').innerHTML = 0;
					bwpcart.updateCartCount(0);
				}

				if (
					event.data.bandwango.externalOrderId &&
					event.data.bandwango.externalOrderId.length > 1
				) {
					localStorage.setItem("bwuid-"+bwpcart.offerId, event.data.bandwango.externalOrderId);
					bwpcart.externalId = event.data.bandwango.externalOrderId;
				}
			}
		}
	},
	resizeSlider: function() {
		var cartWidth = bwpcart.cartWidth();
		var div = document.getElementById("bwcartbox");
		//div.style.height = bwpcart.cartHeight() + "px";
		div.style.width = cartWidth + "px";
		if (bwpcart.cartOpen == false) {
			div.style.right = "-" + cartWidth + "px";
		} else {
			div.style.right = "0px";
		}
		var wrapper = div.querySelector(".bwiframe__wrapper");
		wrapper.style.width = cartWidth + "px";
		var iframe = document.getElementById("bwiframe");
		iframe.setAttribute("height", bwpcart.cartHeight());
		iframe.setAttribute("width", cartWidth);
	},
	renderCustomCss: function() {
		var url = `${bwpcart.checkoutUrl}/api/offers/getPersistentCardCss/${this.offerId}`;
			axios({
				method: 'get',
				url: url,
				responseType: "text"
			}).then((css) => {
				if (css.data) {
					var oStyle = document.createElement("style");
					oStyle.type = "text/css";

					document
						.getElementsByTagName("head")
						.item(0)
						.appendChild(oStyle);

					if (oStyle.styleSheet) {
						// This is required for IE8 and below.
						oStyle.styleSheet.cssText = css.data;
					} else {
						oStyle.appendChild(document.createTextNode(css.data));
					}
				}
			});
	},
	renderModule(el) {
		var moduleId = el.dataset.bwmoduleid;
		var assignmentId = el.dataset.bwassignmentid;
		if(!moduleId) {
			return;
		}
		var url =
			`${bwpcart.checkoutUrl}/api/modules/assignment/${moduleId}/function/getHtml?partnerId=${this.partnerId}&offerId=${this.offerId}&assignmentId=${assignmentId}&external=true`;

		axios.get(url)
			.then(response => response.data)
			.then((json) => {
				var search = json.data;
				var script;

				// Searches for all <script> tags.
				while ((script = search.match(/(<script>\s*(<!--)?)/i))) {
					search = search.substr(
						search.indexOf(RegExp.$1) + RegExp.$1.length
					);
					let endscript, block;
					if (!(endscript = search.match(/((-->)?\s*<\/script>)/))) break;

					block = search.substr(0, search.indexOf(RegExp.$1));
					search = search.substring(block.length + RegExp.$1.length);

					var oScript = document.createElement("script");
					oScript.text = block;
					document
						.getElementsByTagName("head")
						.item(0)
						.appendChild(oScript);
				}

				el.innerHTML = json.data;
			});
	},
	renderModules: function() {
		var bwModules = document.getElementsByClassName("bwmodule");

		for (var i = 0; i < bwModules.length; i++) {
			bwpcart.renderModule(bwModules[i]);
			bwModules[i].setAttribute("data-bw-embed", "true");
		}
	},
	modal(url) {
		window.addEventListener("message",function(event){
			const { data } = event;

			const setHeight = (h) => {
				const modal = document.querySelector('.bwcartModal');
				modal.style.height = 'auto';
				document.getElementById("bwcartModalFrame").height = h;
			}

			if( typeof data === "string" && data.indexOf("height:") > -1 ) {
				setHeight(data.replace("height:", ""));
			}
			
			else if( typeof data === "object" && data.bandwango && data.bandwango.height ) {
				setHeight(data.bandwango.height);
			}
		},!1);
		
		Swal.fire({
			customClass: 'bwcartModal',
			html: `<iframe src="${url}" id="bwcartModalFrame" class="bwcartModal__iframe" style="min-height:600px;"></iframe>`,
			showConfirmButton: false,
			showCloseButton: true,
		});
	},
};

// create an observer instance
const mutationCb = function(mutations) {
	mutations.forEach(function(mutation) {
		switch(mutation.type) {
			case 'childList':
				mutation.addedNodes.forEach(n => {
					if( !(n instanceof HTMLElement) ) {
						return;
					}
					
					n.querySelectorAll('.bwbutton').forEach(function(button) {
						console.log('BW button added to page.');
						bwAddButtonClick(button, bwpcart.showAddToCart)
					});
					
					n.querySelectorAll('.bwiframebutton').forEach(function(button) {
						console.log('BW button added to page.');
						bwAddButtonClick(button, bwpcart.showIframeModal)
					});
					
					n.querySelectorAll('.bwmodule').forEach(function(module) {
						console.log('BW module added to page.');
						bwpcart.renderModule(module);
					});
				})
				break;
		}
	});
};
const mutationConfig = {
	childList: true,
	subtree: true
}
const observer = new MutationObserver(mutationCb);

window.bwpcart = bwpcart;

window.bwjQuery(function() {
	bwpcart.buildCart();
	bwpcart.renderModules();
	bwpcart.buildButtons();
	observer.observe(document.body, mutationConfig)
});